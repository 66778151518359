import React, { useEffect, useMemo, useState } from "react";

// UX
import CardSupplyServiceCheckingUX from "./CardSupplyServiceCheckingUX";

// Common
import SnackMessage from "react-lib/apps/common/SnackMessage";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import { alignRight, formatComma } from "react-lib/apps/common/PureReactTable";

// Interface

import { useIntl } from "react-intl";
import { State, allOptions } from "../sequence/SupplyServiceChecking";

type CardSupplyServiceCheckingProps = {
  onEvent: (e: any) => any;
  setProp: any;
  runSequence: any;
  SupplyServiceCheckingSequence: State["SupplyServiceCheckingSequence"];
  masterOptions?: any;
  errorMessage?: any;
  buttonLoadCheck?: any;
  language?: string;
};

const CARD_NAME = `CardSupplyServiceChecking`;

const CardSupplyServiceChecking = (props: CardSupplyServiceCheckingProps) => {
  const intl = useIntl();

  // combine options
  const combinedProductTypeOptions = [...allOptions, ...(props.masterOptions?.productType || [])];

  const combinedBillModeOptions = [...allOptions, ...(props.masterOptions?.billMode || [])];

  // UseEffect
  useEffect(() => {
    props.runSequence({ sequence: "SupplyServiceChecking", restart: true });
  }, []);

  const handleChangeData = (_event: any, data: any) => {
    if (data.type === "checkbox") {
      props.setProp(`SupplyServiceCheckingSequence.${data.name}`, data.checked);
    } else {
      props.setProp(`SupplyServiceCheckingSequence.${data.name}`, data.value);
    }
  };

  const handleSearch = (_event: any, data: any) => {
    props.runSequence({
      sequence: "SupplyServiceChecking",
      action: data.name,
      card: CARD_NAME,
      page: 0,
    });
  };

  const handlePageSizePage = (pageSize: number)=> {
    props.runSequence({
      sequence: "SupplyServiceChecking",
      action: "SEARCH",
      card: CARD_NAME,
      pageSize: pageSize,
    });
  }

  const handlePageChange = (page: number) => {
    props.runSequence({
      sequence: "SupplyServiceChecking",
      action: "SEARCH",
      card: CARD_NAME,
      page,
    });
  };

  return (
    <>
      {props.errorMessage?.[CARD_NAME] && (
        <SnackMessage
          onEvent={() => props.onEvent}
          onClose={() => {
            props.setProp(`errorMessage.${CARD_NAME}`, null);
          }}
          error={props.errorMessage?.[CARD_NAME]}
          success={null}
        />
      )}

      <CardSupplyServiceCheckingUX
        // func
        onEvent={props.onEvent}
        setProp={props.setProp}
        // seq
        runSequence={props.runSequence}
        SupplyServiceCheckingSequence={props.SupplyServiceCheckingSequence}
        // data
        paginatedItems={props.SupplyServiceCheckingSequence?.paginatedItems}
        // options
        masterOptions={props.masterOptions}
        typeOptions={combinedProductTypeOptions}
        categoryOptions={combinedBillModeOptions}
        // common
        buttonLoadCheck={props.buttonLoadCheck}
        // onChange
        onChangeData={handleChangeData}
        onPageSizeChange={handlePageSizePage}
        onPageChange={handlePageChange}
        // Table
        tableItems={props.SupplyServiceCheckingSequence?.paginatedItems?.items?.map(
          (item: any) => ({
            ...item,
            price_normal: alignRight(formatComma(item.price_normal)),
            price_special: alignRight(formatComma(item.price_special)),
            price_premium: alignRight(formatComma(item.price_premium)),
            price_foreign: alignRight(formatComma(item.price_foreign)),
          })
        )}
        btnSearch={
          <ButtonLoadCheck
            // func
            setProp={props.setProp}
            onClick={handleSearch}
            // data
            paramKey={`${CARD_NAME}_SEARCH`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_NAME}_SEARCH`]}
            // config
            color="blue"
            title={intl.formatMessage({ id: "ค้นหา" })}
            name="SEARCH"
            size="medium"
          />
        }
      />
    </>
  );
};

export default CardSupplyServiceChecking;

import {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Header, Modal, TextArea, Menu, Form } from "semantic-ui-react";

// Framework
import { Table } from "react-lib/frameworks/Table";

// UX
import CardSupplyOrderWorkflowUX from "./CardSupplyOrderWorkflowUX";
import CardReturnSupply from "./CardReturnSupply";
import ModOpenReturnSupplyUX from "./ModOpenReturnSupplyUX";
import CardSupplyOrder from "../CardSupplyOrder";
import CardUserActionLog from "../../common/CardUserActionLog";

// Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import ErrorMessage from "react-lib/apps/common/ErrorMessage";
import ModInfo from "react-lib/apps/common/ModInfo";
import EmployeeToken from "react-lib/apps/common/EmployeeToken";
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import ModLockExpense from "react-lib/apps/HISV3/BIL/ModLockExpense";

// Utils
import { get_status_color, get_type_color } from "./UtilMSD";
import { formatUTCtoBEString } from "react-lib/utils/dateUtils";
import RenderNameStatus from "../RenderNameStatus";
import { useIntl } from "react-intl";

// Types
type CardSupplyOrderWorkflowProps = {
  setProp: (key: string, value: any, callback?: Function) => any;
  onEvent: (e: any) => any;
  // seq
  runSequence: any;
  DispensingOrderSupplySequence?: any;
  ReturnSupplySequence?: any;
  OrderSupplySequence?: any;
  // CommonInterface
  successMessage?: Record<string, any>;
  errorMessage?: Record<string, any>;
  buttonLoadCheck?: Record<string, any>;
  loadingStatus?: any;
  selectedEncounter?: any;
  // options
  supplyOrderEligibilityOptions?: any[];
  masterOptions?: Record<string, any[]>;
  supplyOrderModeOptions?: any[];
  // config
  isReceiveReturn?: boolean;
  // style
  cardStyle?: CSSProperties;
  django?: any;
  parentCard: string;
};

type ModInfoType = {
  open: boolean;
  error?: any;
  title?: string;
  color?: string;
  btnText?: string;
};

const modInfoInitial: ModInfoType = {
  open: false,
  error: null,
  title: "",
  color: undefined,
  btnText: "",
};

// Const

const actionCheckPrint = ["HOME_OPD", "HOME_IPD", "ONE_DAY", "STAT"];

const actionVerify = ["REFILL_IPD", "REFILL_OPD", "COST_CENTER"];

const CARD_SUPPLY_WORKFLOW = "CardSupplyOrderWorkflow";
const CARD_RETURN_SUPPLY = "CardReturnSupply";
const MOD_RETURN_SUPPLY = "ModOpenReturnSupplyUX ";
const MOD_CANCEL_SUPPLY_WORKFLOW = "ModCancelSupplyOrderWorkflow";
const CARD_SUPPLY_ORDER = "CardSupplyOrder";

const CardSupplyOrderWorkflow = (props: CardSupplyOrderWorkflowProps) => {
  const intl = useIntl();
  const [action, setAction] = useState<any>("");
  const [tab, setTab] = useState("transporter");

  const [note, setNote] = useState<any>("");
  const [nameCancel, setNameCancel] = useState<any>("");
  const [reason, setReason] = useState<any>(null);
  const [quantityRestock, setQuantityRestock] = useState<any>("");

  const [selectedRow, setSelectedRow] = useState<any>(null);
  // Mod
  const [openModQuantity, setOpenModQuantity] = useState(false);
  const [openActionLog, setOpenActionLog] = useState(false);
  const [openModCancel, setOpenModCancel] = useState(false);
  const [openModPaid, setModPaid] = useState<ModInfoType>(modInfoInitial);
  const [openModEdit, setOpenModEdit] = useState<boolean>(false);
  const [openModReturnSupply, setOpenModReturnSupply] =
    useState<boolean>(false);
  const [openModInfo, setOpenModInfo] = useState<ModInfoType>(modInfoInitial);
  const [modReturnRequestSupply, setModReturnRequestSupply] = useState<
    number | null
  >(null);

  // Effect
  useEffect(() => {
    props.runSequence({ sequence: "OrderSupply" });
  }, []);

  useEffect(() => {
    if (
      (props.successMessage?.[CARD_SUPPLY_WORKFLOW] ||
        props.successMessage?.[MOD_CANCEL_SUPPLY_WORKFLOW]) &&
      props.DispensingOrderSupplySequence?.openModInfo
    ) {
      setSelectedRow(null);
      setOpenModCancel(false);
      setAction(null);
      setNameCancel(null);
      setNote(null);

      props.setProp("DispensingOrderSupplySequence", {
        ...props.DispensingOrderSupplySequence,
        coUserToken: "",
        coUser: "",
        loading: false,
        error: null,
      });

      setOpenModInfo({
        open: true,
        title: props.DispensingOrderSupplySequence?.titlePopup,
        color: "green",
        btnText: "ปิด",
      });
    }

    if (
      props.successMessage?.[MOD_RETURN_SUPPLY] &&
      props.DispensingOrderSupplySequence?.openModInfo
    ) {
      setOpenModReturnSupply(false);

      setReason(null);
      setQuantityRestock("");

      setOpenModInfo({
        open: true,
        title: props.DispensingOrderSupplySequence?.titlePopup,
        color: "green",
        btnText: "ตกลง",
      });
    }
  }, [
    props.successMessage?.[CARD_SUPPLY_WORKFLOW],
    props.successMessage?.[MOD_CANCEL_SUPPLY_WORKFLOW],
    props.successMessage?.[MOD_RETURN_SUPPLY],
  ]);

  useEffect(() => {
    if (props?.successMessage?.[CARD_SUPPLY_ORDER]) {
      setOpenModEdit(false);
    }
  }, [props?.successMessage?.[CARD_SUPPLY_ORDER]]);

  useEffect(() => {
    if (
      props.DispensingOrderSupplySequence?.selectedOrder?.status ===
        "CHECKED" &&
      !props.DispensingOrderSupplySequence?.selectedOrder?.is_paid
    ) {
      setModPaid({
        open: true,
        title: "รายการเวชภัณฑ์ยังไม่ได้รับชำระเงิน",
        color: "red",
        btnText: "ตกลง",
      });
    }
    if (
      props.DispensingOrderSupplySequence?.selectedOrder?.status === "CHECKED" &&
      ["IPD", "OPD"].includes(props?.selectedEncounter?.type || "")
    ) {
      props.setProp("DispensingOrderSupplySequence.isTransporter", true);
    }
  }, [props.DispensingOrderSupplySequence?.selectedOrder?.status]);

  useEffect(() => {
    if (props.DispensingOrderSupplySequence?.selectedOrderId) {
      props.runSequence({
        sequence: "DispensingOrderSupply",
        action: "refresh",
      });
    }
  }, [props.DispensingOrderSupplySequence?.selectedOrderId]);

  const handleRefreshSupplyDetail = useCallback(() => {
    props.runSequence({
      sequence: "DispensingOrderSupply",
      action: "refresh",
      orderId: selectedOrder?.id,
    });
  }, [props.runSequence]);

  const handleGetActionLog = useCallback(() => {
    props.runSequence({
      sequence: "DispensingOrderSupply",
      action: "getActionLog",
    });
    setOpenActionLog(true);
  }, [props.runSequence]);

  const TYPE_STATUS: { [key: string]: string } = useMemo(
    () => ({
      "HOME_OPD:REQUESTED": "ตรวจเวชภัณฑ์กลับบ้าน (VERIFY)",
      "HOME_OPD:PRINTED": "จัดเวชภัณฑ์กลับบ้าน (CHECK)",
      "HOME_OPD:CHECKED": "จ่ายเวชภัณฑ์กลับบ้านให้กับผู้ป่วย (DELIVER)",
      HOME_OPD: "รายละเอียดการสั่งเวชภัณฑ์กลับบ้าน",
      "HOME_IPD:REQUESTED": "ตรวจเวชภัณฑ์กลับบ้าน (VERIFY)",
      "HOME_IPD:PRINTED": "จัดเวชภัณฑ์กลับบ้าน (CHECK)",
      "HOME_IPD:CHECKED": "จ่ายเวชภัณฑ์กลับบ้านให้กับผู้ป่วย (DELIVER)",
      HOME_IPD: "รายละเอียดการสั่งเวชภัณฑ์กลับบ้าน",
      "ONE_DAY:REQUESTED": "ตรวจเวชภัณฑ์เบิกใช้ (VERIFY)",
      "ONE_DAY:PRINTED": "จัดเวชภัณฑ์เบิกใช้ (CHECK)",
      "ONE_DAY:CHECKED": "จ่ายเวชภัณฑ์เบิกใช้ (DELIVER)",
      ONE_DAY: "รายละเอียดการสั่งเวชภัณฑ์เบิกใช้",
      "STAT:REQUESTED": "ตรวจเวชภัณฑ์เร่งด่วน (VERIFY)",
      "STAT:PRINTED": "จัดเวชภัณฑ์เร่งด่วน (CHECK)",
      "STAT:CHECKED": "จ่ายเวชภัณฑ์เร่งด่วน (DELIVER)",
      STAT: "รายละเอียดการสั่งเวชภัณฑ์เร่งด่วน",
      "REFILL_OPD:REQUESTED": "ตรวจเวชภัณฑ์ที่ใช้รักษาผู้ป่วยนอก (VERIFY)",
      REFILL_OPD: "รายละเอียดเวชภัณฑ์ที่ใช้รักษาผู้ป่วยนอก",
      "REFILL_IPD:REQUESTED": "ตรวจเวชภัณฑ์ที่ใช้รักษาผู้ป่วยใน (VERIFY)",
      REFILL_IPD: "รายละเอียดเวชภัณฑ์ที่ใช้รักษาผู้ป่วยใน",
      "COST_CENTER:REQUESTED": "ตรวจเวชภัณฑ์เหมารวมหัตถการ (VERIFY)",
      "COST_CENTER:VERIFIED": "รายละเอียดเวชภัณฑ์เหมารวมหัตถการ",
      "": "รายละเอียดการสั่งเวชภัณฑ์",
    }),
    []
  );

  const selectedOrder = useMemo(() => {
    return props.DispensingOrderSupplySequence?.selectedOrder;
  }, [props.DispensingOrderSupplySequence?.selectedOrder]);

  const isCostCenter = useMemo(() => {
    return selectedOrder?.type === "COST_CENTER";
  }, [selectedOrder?.type]);

  const titleRibbon = useMemo(
    () => (selectedOrder ? `[${selectedOrder?.type || ""}][${selectedOrder?.code || ""}]` : ""),
    [selectedOrder?.type, selectedOrder?.code]
  );

  const title = useMemo(() => {
    let key = [selectedOrder?.type, selectedOrder?.status].join(":");
    return (
      TYPE_STATUS[key] || TYPE_STATUS[selectedOrder?.type] || TYPE_STATUS[""]
    );
  }, [selectedOrder?.type, selectedOrder?.status]);

  const ribbonColor = useMemo(
    () => get_type_color(selectedOrder?.type),
    [selectedOrder?.type]
  );

  const statusColor = useMemo(
    () => get_status_color(selectedOrder?.status),
    [selectedOrder?.status]
  );

  const isPaidColor = useMemo(() => {
    let is_paid = selectedOrder?.is_paid;
    if (is_paid === true) {
      return "green";
    } else if (is_paid === false) {
      return "red";
    } else {
      return "grey";
    }
  }, [selectedOrder?.is_paid]);

  const isPaid = useMemo(() => {
    let is_paid = selectedOrder?.is_paid;
    if (is_paid === null || is_paid === undefined) {
      return "";
    } else if (is_paid === true) {
      return "ชำระเงินแล้ว";
    } else if (is_paid === false) {
      return "ยังไม่ชำระเงิน";
    }
  }, [selectedOrder?.is_paid]);

  const summaryPrice: any = useMemo(() => {
    let initial = {
      value_total: 0,
      price_total: 0,
      price_claimable: 0,
      price_non_claimable: 0,
      price_discount: 0,
    };
    const totalKey = isCostCenter ? "value_total" : "price_total";
    let prepare = null;
    if (selectedOrder?.items?.length > 0) {
      prepare = (selectedOrder?.items || [])?.reduce((prev: any, item: any) => {
        return {
          [totalKey]: prev?.[totalKey] + item?.[totalKey],
          price_claimable: prev?.price_claimable + item?.price_claimable,
          price_non_claimable:
            prev?.price_non_claimable + item?.price_non_claimable,
          price_discount: prev?.price_discount + item?.price_discount,
        };
      }, initial);
    } else {
      prepare = initial;
    }

    prepare = {
      ...prepare,
      price_total: prepare?.[totalKey],
    };

    return prepare;
  }, [selectedOrder?.items, isCostCenter]);

  const supplyDatas = useMemo(
    () =>
      (selectedOrder?.items || [])?.map((item: any, idx: any) => ({
        ...item,
        // name: item.name,
        nameStatus: <RenderNameStatus hideStatus={true} data={item} />,
        eligibility_type: props.supplyOrderEligibilityOptions?.find(
          (options: any) => options.key === item.eligibility_type
        )?.text,
        quantity_issue: (
          <input
            style={{
              width: "100%",
              border: "none",
              backgroundColor:
                selectedOrder?.status === "REQUESTED"
                  ? "rgb(255, 255, 204)"
                  : "",
            }}
            type="number"
            readOnly={selectedOrder?.status !== "REQUESTED"}
            min={0}
            value={item.quantity_issue}
            onBlur={(e: any) => {
              props.runSequence({
                sequence: "DispensingOrderSupply",
                action: "change",
                item: item,
                change: "quantity_issue",
              });
            }}
            onChange={(e: any) => {
              let items: any[] = selectedOrder?.items;

              if (items?.[idx]) {
                items[idx].quantity_issue = e.target.value;
                props.setProp(
                  "DispensingOrderSupplySequence.selectedOrder.items",
                  [...items]
                );
              }
            }}
          />
        ),
        quantity_postpone: (
          <input
            style={{
              width: "100%",
              border: "none",
              backgroundColor:
                selectedOrder?.status === "REQUESTED"
                  ? "rgb(255, 255, 204)"
                  : "",
            }}
            readOnly={selectedOrder?.status === "REQUESTED" ? false : true}
            type="number"
            min={0}
            value={item.quantity_postpone}
            onBlur={() => {
              props.runSequence({
                sequence: "DispensingOrderSupply",
                action: "change",
                item: item,
                change: "quantity_postpone",
              });
            }}
            onChange={(e: any) => {
              let items: any[] = selectedOrder?.items;
              if (items?.[idx]) {
                items[idx].quantity_postpone = e.target.value;
                props.setProp(
                  "DispensingOrderSupplySequence.selectedOrder.items",
                  [...items]
                );
              }
            }}
          />
        ),
      })),
    [selectedOrder?.items, props.supplyOrderEligibilityOptions]
  );

  const showButtonRequestReturn = useMemo(() => {
    const showButton =
      (props.selectedEncounter?.type === "IPD" &&
        selectedOrder?.status === "RECEIVED" &&
        selectedOrder?.type !== "HOME_IPD") ||
      (props.selectedEncounter?.type === "IPD" &&
        selectedOrder?.type === "ONE_DAY");

    return ["ONE_DAY", "STAT"].includes(selectedOrder?.type || "")
      ? ["IPD", "OPD"].includes(props.selectedEncounter?.type || "") &&
          selectedOrder?.status === "RECEIVED"
      : showButton;
  }, [
    props.isReceiveReturn,
    props.selectedEncounter?.type,
    selectedOrder?.type,
    selectedOrder?.status,
  ]);

  const showButtonReturn = useMemo(() => {
    const showButton =
      (["DELIVERED", "RECEIVED"].includes(selectedOrder?.status || "") ||
        (selectedOrder?.status === "TRANSPORTED" &&
          selectedOrder?.order_payment_status === 3)) &&
      selectedOrder?.type !== "ONE_DAY";

    return props.isReceiveReturn ? false : showButton;
  }, [props.isReceiveReturn, selectedOrder?.type, selectedOrder?.status]);

  const unDeliverIPD = useMemo(() => {
    const showButton =
      props.selectedEncounter?.type === "IPD" &&
      (selectedOrder?.status === "TRANSPORTED" ||
        (selectedOrder?.status === "RECEIVED" &&
          selectedOrder?.type === "STAT") ||
        selectedOrder?.type === "ONE_DAY");

    return ["ONE_DAY", "STAT"].includes(selectedOrder?.type || "")
      ? props.isReceiveReturn
        ? false
        : ["RECEIVED", "TRANSPORTED"].includes(selectedOrder?.status || "")
      : showButton;
  }, [
    props.isReceiveReturn,
    props.selectedEncounter?.type,
    selectedOrder?.type,
    selectedOrder?.status,
  ]);

  const unDeliverOPD = useMemo(() => {
    const showButton =
      props.selectedEncounter?.type === "OPD" &&
      selectedOrder?.status === "DELIVERED";

    return props.isReceiveReturn ? false : showButton;
  }, [
    props.isReceiveReturn,
    props.selectedEncounter?.type,
    selectedOrder?.status,
  ]);

  const handleVerify = async () => {
    if (actionCheckPrint.includes(selectedOrder?.type || "")) {
      const isLocked = await props.onEvent({ message: "CheckEncounterLocker" });

      if (isLocked) {
        return;
      }
    }

    let items: any[] = selectedOrder?.items || [];

    const openVerify = items.every((item) => {
      const total_quantity = item.quantity_issue + item.quantity_postpone;

      return (
        total_quantity === item.quantity_request && item.quantity_postpone === 0
      );
    });

    if (openVerify) {
      const type = selectedOrder?.type;

      props.runSequence({
        sequence: "DispensingOrderSupply",
        action: actionCheckPrint?.includes(type)
          ? "PRINT"
          : actionVerify?.includes(type)
          ? "VERIFY"
          : "NO_ACTION",
        card: CARD_SUPPLY_WORKFLOW,
        buttonLoadKey: actionCheckPrint?.includes(type)
          ? `${CARD_SUPPLY_WORKFLOW}_CHECK_PRINT`
          : actionVerify?.includes(type)
          ? `${CARD_SUPPLY_WORKFLOW}_VERIFY`
          : "",
        note: note || "",
      });
    } else {
      setOpenModQuantity(true);
    }
  };

  const handleCheck = async ()=> {
    const isLocked = await props.onEvent({ message: "CheckEncounterLocker" });

    if (isLocked) {
      return;
    }

    props.runSequence({
      sequence: "DispensingOrderSupply",
      action: "CHECK",
      card: CARD_SUPPLY_WORKFLOW,
      buttonLoadKey: `${CARD_SUPPLY_WORKFLOW}_CHECK`,
    });
  }

  const coUserVerify = () => {
    let promise = props.onEvent({
      message: "checkUserTokenize",
      params: {
        coUser: props.DispensingOrderSupplySequence?.coUser,
      },
    });

    promise.then((result: any) => {
      props.setProp("DispensingOrderSupplySequence.coUser", result.coUser);
      props.setProp(
        "DispensingOrderSupplySequence.coUserToken",
        result.coUserToken
      );
    });
  };

  const handleCloseModInfo = () => {
    setOpenModInfo(modInfoInitial);

    props.setProp("DispensingOrderSupplySequence", {
      ...props.DispensingOrderSupplySequence,
      openModInfo: false,
      titlePopup: "",
    });
  };

  const handleRowProps = (
    state: any,
    rowInfo: any,
    column: any,
    instance: any
  ) => {
    return {
      style: {
        backgroundColor:
          rowInfo?.original?.id && rowInfo?.original?.id === selectedRow?.id
            ? "#cccccc"
            : "white",
      },
      onClick: () => setSelectedRow(rowInfo.original),
    };
  };

  const handleApprove = () => {
    const type = selectedOrder?.type;

    props.runSequence({
      sequence: "DispensingOrderSupply",
      action: actionCheckPrint?.includes(type)
        ? "PRINT"
        : actionVerify?.includes(type)
        ? "VERIFY"
        : "NO_ACTION",
      card: CARD_SUPPLY_WORKFLOW,
      buttonLoadKey: actionCheckPrint?.includes(type)
        ? `${CARD_SUPPLY_WORKFLOW}_CHECK_PRINT`
        : actionVerify?.includes(type)
        ? `${CARD_SUPPLY_WORKFLOW}_VERIFY`
        : "",
      note: note,
    });

    setOpenModQuantity(false);
  };

  const handleClose = () => {
    setOpenModQuantity(false);
  };

  const handleCloseModCancel = () => {
    setOpenModCancel(false);
    setNote(null);
    setAction(null);
    setNameCancel(null);

    props.setProp(`errorMessage.${MOD_CANCEL_SUPPLY_WORKFLOW}`, null);
  };

  const handleClearToken = () => {
    props.setProp("DispensingOrderSupplySequence", {
      ...props.DispensingOrderSupplySequence,
      coUserToken: "",
      coUser: "",
      loading: false,
      error: null,
    });
  };

  const handleSelectPatientDeliver = async () => {
    setTab("patient");
    await props.setProp("DispensingOrderSupplySequence.isTransporter", false);

    handleClearToken();
  };

  // console.log("CardSupplyOrderWorkflow summaryPrice: " , summaryPrice)
  console.log("CardSupplyOrderWorkflow selectedOrder: ", selectedOrder);
  console.log(
    "CardSupplyOrderWorkflow  selectedOrder?.status: ",
    selectedOrder?.status
  );
  console.log(
    "CardSupplyOrderWorkflow  props.django?.user?.role_types: ",
    props.django?.user?.role_types
  );
  let a = [
    "DOCTOR",
    "PHARMACIST",
    "REGISTERED_NURSE",
    "ADMIT_STAFF",
    "TECHNICIAN",
    "TECHNICIAN_PMR",
    "NUTRITIONISTS",
    "PEDIATRIC_DENTISTS",
    "AIRFLOW",
  ].some((r: any) => props.django?.user?.role_types?.includes(r));

  console.log("CardSupplyOrderWorkflow a: ", a);
  console.log("CardSupplyOrderWorkflow props.parentCard: ", props.parentCard);

  return (
    <div style={{ paddingBottom: "2rem", ...(props.cardStyle || {}) }}>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_RETURN_SUPPLY}.error`, null);
        }}
        error={props?.errorMessage?.[CARD_RETURN_SUPPLY]?.error}
        success={null}
        languageUX={props.languageUX}
      />

      <Modal
        onClose={() => setOpenActionLog(false)}
        onOpen={() => setOpenActionLog(true)}
        open={openActionLog}
      >
        <ActionLog data={props.DispensingOrderSupplySequence?.actionLog} />
      </Modal>

      <CardSupplyOrderWorkflowUX
        // data
        // Title
        title={title}
        titleRibbon={titleRibbon}
        ribbonColor={ribbonColor}
        status={selectedOrder?.status}
        statusColor={statusColor}
        isPaid={isPaid}
        isPaidColor={isPaidColor}
        type={props.selectedEncounter?.type}
        // Table
        headers={
          isCostCenter &&
          "No,Code,Mode,Product Name,ขนาดบรรจุ,คงคลัง,จำนวนสั่ง,จะจ่าย,หน่วยสั่ง,มูลค่า,มูลค่ารวม"
        }
        keys={
          isCostCenter &&
          "no,code,mode,name,package_size,stock_balance,quantity_request, quantity_issue,stock_unit, value_unit, value_total"
        }
        widths={isCostCenter && "^40,^100,^105,^100,^100,^60,^80,^60,^100,^100,^100"}
        checkOPD={
          selectedOrder?.status === "CHECKED" && props.selectedEncounter?.type === "OPD"
          // || isPaid === "ชำระเงินแล้ว"
        }
        checkIPD={props?.selectedEncounter?.type === "IPD" && selectedOrder?.status === "CHECKED"}
        unDeliverIPD={unDeliverIPD}
        unDeliverOPD={unDeliverOPD}
        isCheck={selectedOrder?.status === "CHECKED"}
        unCheck={selectedOrder?.status === "CHECKED"}
        showButtonReturn={showButtonReturn}
        showButtonRequestReturn={showButtonRequestReturn}
        // Issue 63692 ต้องการกันแค่ เจ้าหน้าที่เวช ที่ เป็น GENERAL แต่ implement แบบนี้
        showButtonReceived={
          [
            "DOCTOR",
            "PHARMACIST",
            "REGISTERED_NURSE",
            "ADMIT_STAFF",
            "TECHNICIAN",
            "TECHNICIAN_PMR",
            "NUTRITIONISTS",
            "PEDIATRIC_DENTISTS",
            "AIRFLOW",
          ].some((r: any) => props.django?.user?.role_types?.includes(r)) &&
          selectedOrder?.status === "TRANSPORTED" &&
          props.parentCard === "CardReceiveOrder"
        }
        hideBtnUnDeliver={
          (!unDeliverIPD && !unDeliverOPD) ||
          (props.parentCard === "CardReceiveOrder" && selectedOrder?.type === "HOME_IPD")
        }
        userActionLogList={
          <CardUserActionLog
            data={selectedOrder}
            actions={[
              "ผู้สั่ง",
              "ผู้ตรวจ",
              "ผู้จัด",
              "ผู้จ่าย",
              "ผู้ขนส่ง",
              "ผู้รับ",
              "ผู้ตรวจ",
              "ผู้ปฏิเสธ",
              "ผู้ยกเลิก",
            ]}
            languageUX={props.languageUX}
          />
        }
        supplyData={supplyDatas}
        sumPriceTotal={summaryPrice?.price_total || "0"}
        sumPriceClaimable={summaryPrice?.price_claimable || "0"}
        sumPriceNonClaimable={summaryPrice?.price_non_claimable || "0"}
        sumPriceDiscount={summaryPrice?.price_discount || "0"}
        coUser={props.DispensingOrderSupplySequence?.coUser}
        selectedRow={selectedRow}
        isCostCenter={isCostCenter}
        // callback
        refreshSupply={handleRefreshSupplyDetail}
        getActionLog={handleGetActionLog}
        onChecked={handleVerify}
        coUserVerify={coUserVerify}
        getTrProps={handleRowProps}
        returnSupply={() => setOpenModReturnSupply(true)}
        changeCoUser={(e: any) =>
          props.setProp("DispensingOrderSupplySequence.coUser", e.target.value)
        }
        OnReturn={() => {
          setModReturnRequestSupply(selectedOrder?.id);
        }}
        OnUndeliver={() => {
          setOpenModCancel(true);
          setAction("UNDELIVER");
          setNameCancel("ยกเลิกการจ่าย");
        }}
        OnUnCheck={() => {
          setOpenModCancel(true);
          setAction("UNCHECK");
          setNameCancel("ยกเลิกการจัด");
        }}
        OnUnVerify={() => {
          setOpenModCancel(true);
          setAction("UNVERIFY");
          setNameCancel("ยกเลิกการตรวจ");
        }}
        OnCancel={() => {
          setOpenModCancel(true);
          setAction("CANCEL");
          setNameCancel("ยกเลิกการสั่ง");
        }}
        onEdit={() => {
          setOpenModEdit(true);

          props.runSequence({
            sequence: "OrderSupply",
            action: "selectedHistory",
            original: selectedOrder,
          });
        }}
        // Element
        errorMessage={<ErrorMessage error={props.errorMessage?.[CARD_SUPPLY_WORKFLOW]?.error} />}
        buttonRePrint={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => {
              props.runSequence({
                sequence: "DispensingOrderSupply",
                action: "REPRINT",
                card: CARD_SUPPLY_WORKFLOW,
                buttonLoadKey: `${CARD_SUPPLY_WORKFLOW}_REPRINT`,
              });
            }}
            // data
            paramKey={`${CARD_SUPPLY_WORKFLOW}_REPRINT`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_SUPPLY_WORKFLOW}_REPRINT`]}
            // config
            size="small"
            name="REPRINT"
            style={{ width: "100%" }}
            title="REPRINT"
          />
        }
        additionalProps={
          ["IPD", "OPD"].includes(props.selectedEncounter?.type) &&
          selectedOrder?.status === "CHECKED" && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              {tab === "transporter" && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ padding: "0px 0.5rem", minWidth: "max-content" }}>
                    {intl.formatMessage({ id: "ผู้จัดส่ง" })}
                  </span>

                  <EmployeeToken
                    size="small"
                    onEnterToken={(code) => {
                      props.onEvent({
                        message: "HandleGetDeliverSupplyTokenization",
                        params: { code },
                      });
                    }}
                    onClearToken={handleClearToken}
                    placeholder="Code"
                    error={props.DispensingOrderSupplySequence.error}
                    loading={props.DispensingOrderSupplySequence.loading}
                    employeeName={props?.DispensingOrderSupplySequence?.coUser || null}
                  />
                </div>
              )}

              <>
                <Menu inverted size="small" compact style={{ minHeight: "2.6em" }}>
                  <Menu.Item
                    name="patient"
                    color={tab === "patient" ? "yellow" : "black"}
                    active={tab === "patient"}
                    style={{ paddingTop: 0, paddingBottom: 0 }}
                    onClick={handleSelectPatientDeliver}
                  >
                    ผู้ป่วยรับเอง
                  </Menu.Item>
                  <Menu.Item
                    name="transporter"
                    color={tab === "transporter" ? "yellow" : "black"}
                    active={tab === "transporter"}
                    style={{ paddingTop: 0, paddingBottom: 0 }}
                    onClick={() => {
                      setTab("transporter");
                      props.setProp("DispensingOrderSupplySequence.isTransporter", true);
                    }}
                  >
                    พนักงานจัดส่ง
                  </Menu.Item>
                </Menu>
              </>
            </div>
          )
        }
        buttonCheckOrder={
          actionCheckPrint?.includes(selectedOrder?.type) ? (
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={handleVerify}
              // data
              paramKey={`${CARD_SUPPLY_WORKFLOW}_CHECK_PRINT`}
              buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_SUPPLY_WORKFLOW}_CHECK_PRINT`]}
              // config
              name="CHECK_PRINT"
              size="small"
              style={{ width: "100%" }}
              title={intl.formatMessage({ id: "ตรวจรายการสั่ง" })}
              color="green"
            />
          ) : actionVerify?.includes(selectedOrder?.type) ? (
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={handleVerify}
              // data
              paramKey={`${CARD_SUPPLY_WORKFLOW}_VERIFY`}
              buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_SUPPLY_WORKFLOW}_VERIFY`]}
              // config
              name="VERIFY"
              size="small"
              style={{ width: "100%" }}
              title={intl.formatMessage({ id: "ตรวจรายการสั่ง" })}
              color="green"
            />
          ) : (
            ""
          )
        }
        buttonCheck={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleCheck}
            // data
            paramKey={`${CARD_SUPPLY_WORKFLOW}_CHECK`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_SUPPLY_WORKFLOW}_CHECK`]}
            // config
            name="CHECK"
            size="small"
            style={{ width: "100%" }}
            title={intl.formatMessage({ id: "จัดเวชภัณฑ์" })}
            color="green"
          />
        }
        buttonPaid={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => {
              props.runSequence({
                sequence: "DispensingOrderSupply",
                action: "DELIVER",
                card: CARD_SUPPLY_WORKFLOW,
                buttonLoadKey: `${CARD_SUPPLY_WORKFLOW}_DELIVER`,
                type: props?.selectedEncounter?.type,
                deliverBy: tab,
              });
            }}
            // data
            paramKey={`${CARD_SUPPLY_WORKFLOW}_DELIVER`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_SUPPLY_WORKFLOW}_DELIVER`]}
            // config
            name="DELIVER"
            size="small"
            style={{ width: "100%" }}
            title={intl.formatMessage({ id: "จ่ายเวชภัณฑ์" })}
            color="green"
          />
        }
        buttonReceive={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => {
              props.runSequence({
                sequence: "DispensingOrderSupply",
                action: "RECEIVE",
                card: CARD_SUPPLY_WORKFLOW,
                buttonLoadKey: `${CARD_SUPPLY_WORKFLOW}_RECEIVE`,
              });
            }}
            // data
            paramKey={`${CARD_SUPPLY_WORKFLOW}_RECEIVE`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_SUPPLY_WORKFLOW}_RECEIVE`]}
            // config
            name="RECEIVE"
            size="small"
            style={{ width: "100%" }}
            title={intl.formatMessage({ id: "รับเวชภัณฑ์" })}
            color="green"
          />
        }
        languageUX={props.languageUX}
      />

      <ModConfirm
        openModal={openModCancel}
        titleName={`ยืนยัน ${nameCancel} ?`}
        onDeny={handleCloseModCancel}
        onCloseWithDimmerClick={handleCloseModCancel}
        onApprove={() =>
          props.runSequence({
            sequence: "DispensingOrderSupply",
            action: action,
            card: MOD_CANCEL_SUPPLY_WORKFLOW,
            note: note,
          })
        }
        content={
          <div style={{ margin: "-1rem 0px -1.5rem" }}>
            <div style={{ marginBottom: "0.5rem" }}>
              <ErrorMessage error={props?.errorMessage?.[MOD_CANCEL_SUPPLY_WORKFLOW]?.error} />
            </div>
            <div>{intl.formatMessage({ id: "สาเหตุ" })}</div>
            <Form>
              <TextArea
                style={{
                  border: "solid #cccccc 1px",
                  marginTop: "5px",
                }}
                rows={4}
                value={note}
                onChange={(e, v) => {
                  setNote(v.value);
                }}
              />
            </Form>
          </div>
        }
      />

      <Modal
        open={!!modReturnRequestSupply}
        size="large"
        onClose={() => {
          setModReturnRequestSupply(null);
        }}
      >
        <CardReturnSupply
          setProp={props.setProp}
          onEvent={props.onEvent}
          // seq
          runSequence={props.runSequence}
          ReturnSupplySequence={props.ReturnSupplySequence}
          // data
          isPaid={selectedOrder?.is_paid}
          orderId={modReturnRequestSupply}
          // options
          masterOptions={props.masterOptions}
          // CommonInterface
          errorMessage={props.errorMessage}
          loadingStatus={props.loadingStatus}
          buttonLoadCheck={props.buttonLoadCheck}
          // config
          hideErrorMessage={true}
          isReceiveReturn={props.isReceiveReturn}
          // callback
          onClose={() => setModReturnRequestSupply(null)}
          languageUX={props.languageUX}
        />
      </Modal>

      <Modal
        open={openModReturnSupply}
        onClose={() => {
          setOpenModReturnSupply(false);
          props.setProp(`errorMessage.${MOD_RETURN_SUPPLY}`, null);
        }}
      >
        <ModOpenReturnSupplyUX
          // data
          selectedRow={selectedRow}
          reason={reason}
          // callback
          onChange={(e: any, v: any) => {
            setReason(v.value);
          }}
          onSave={() => {
            props.runSequence({
              sequence: "DispensingOrderSupply",
              action: "returnSupply",
              card: MOD_RETURN_SUPPLY,
              item: {
                order_item: selectedRow?.id,
                quantity_discard: "0",
                quantity_restock: quantityRestock,
                reason: reason,
              },
            });
          }}
          // Element
          errorMessage={<ErrorMessage error={props?.errorMessage?.[MOD_RETURN_SUPPLY]?.error} />}
          returnedQuantity={
            <input
              style={{
                width: "100%",
              }}
              type="number"
              min={0}
              max={selectedRow?.quantity_request}
              value={quantityRestock}
              onChange={(e: any) => {
                setQuantityRestock(e.target.value);
              }}
            />
          }
        />
      </Modal>

      <Modal
        open={openModEdit}
        style={{
          width: "auto",
          margin: "2rem 0 0",
        }}
        onClose={() => setOpenModEdit(false)}
      >
        <CardSupplyOrder
          setProp={props.setProp}
          onEvent={props.onEvent}
          // seq
          runSequence={props.runSequence}
          OrderSupplySequence={props.OrderSupplySequence}
          // options
          supplyOrderModeOptions={props.supplyOrderModeOptions}
          supplyOrderEligibilityOptions={props.supplyOrderEligibilityOptions}
          masterOptions={props.masterOptions}
          // CommonInterface
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
          selectedEncounter={props.selectedEncounter}
          buttonLoadCheck={props.buttonLoadCheck}
          // style
          cardStyle={{ padding: "1rem 1rem 0" }}
          // config
          isSupplyOrderWorkflow={true}
          languageUX={props.languageUX}
        />
      </Modal>

      <ModInfo
        open={openModInfo.open}
        titleColor={openModInfo.color}
        titleName={openModInfo.title}
        btnText={openModInfo.btnText}
        onApprove={handleCloseModInfo}
        onClose={handleCloseModInfo}
      ></ModInfo>

      <ModInfo
        open={openModPaid.open}
        titleColor={openModPaid.color}
        titleName={openModPaid.title}
        btnText={openModPaid.btnText}
        onApprove={() => setModPaid({ open: false })}
        onClose={() => setModPaid({ open: false })}
      ></ModInfo>

      <ModConfirm
        openModal={openModQuantity}
        titleName={intl.formatMessage({ id: "กรณีแก้ไขจำนวนหรือแก้ไขเวชภัณฑ์ กรุณากรอกเหตุผล" })}
        onDeny={handleClose}
        onCloseWithDimmerClick={handleClose}
        onApprove={handleApprove}
        content={
          <div style={{ margin: "-1rem 0px -1.5rem" }}>
            <div style={{ marginBottom: "0.5rem" }}>
              {/* <ErrorMessage error={props?.errorMessage?.[MOD_CANCEL]?.error} /> */}
            </div>
            <div>{intl.formatMessage({ id: "สาเหตุ" })}</div>
            <Form>
              <TextArea
                style={{
                  width: "100%",
                  height: "100%",
                  border: "solid #cccccc 1px",
                  marginTop: "5px",
                }}
                rows={4}
                value={note}
                onChange={(e, v) => setNote(v.value)}
              />
            </Form>
          </div>
        }
      />

      <ModLockExpense
        onEvent={props.onEvent}
        setProp={props.setProp}
        buttonLoadCheck={props.buttonLoadCheck}
        errorMessage={props.errorMessage}
      />
    </div>
  );
};

/* ------------------------------------------------------ */

/*                       ActionLog;                       */

/* ------------------------------------------------------ */
const ActionLog = (props: any) => {
  const intl = useIntl();
  const data = useMemo(
    () =>
      (props.data || [])?.map((item: any) => ({
        ...item,
        datetime: formatUTCtoBEString(item.datetime)?.join(" "),
      })),
    [props.data]
  );

  return (
    <div style={{ padding: "10px" }}>
      <Header>{intl.formatMessage({ id: "ประวัติการทำงาน" })}</Header>
      <Table
        data={data}
        headers="User,Action,note,division,Date-Time"
        keys="name,action,note,division,datetime"
        minRows="10"
        showPagination={false}
        style={{ height: "300px" }}
      ></Table>
    </div>
  );
};

export default CardSupplyOrderWorkflow;
